import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { navigate } from 'gatsby';

import styled from '@emotion/styled';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import MaskInputController from 'components/MaskInputController';
import Modal, { ModalProps } from 'components/Modal';
import { REGEXP } from 'consts';
import { DefaultCommissionsForm } from 'components/forms';
import Context from './UserManagementContext';
import { inviteUser as inviteUserRequest } from './api';
import { AgentTabKeys, TeamMember } from './usermanagement.d';
import sign from 'pages/pdf-editor/sign';

type FormState = {
  email: string;
  name: string;
  phone: string;
  team_id: number;
  position: string;
  has_signed_contract: boolean;
} & Partial<CommissionsData>;
interface InviteUserProps extends ModalProps {
  setOpenTab?: React.Dispatch<React.SetStateAction<AgentTabKeys | undefined>>;
  loadCommissionsInfo: () => Promise<CommissionsData>;
}

const InviteUser: React.FC<InviteUserProps> = ({
  onClose,
  setOpenTab,
  loadCommissionsInfo,
  ...props }) => {
  const {
    register,
    control,
    formState: { errors },
    formState,
    reset,
    handleSubmit
  } = useForm({
    defaultValues: {
      email: '',
      name: '',
      phone: '',
      team_id: null,
      position: '',
      has_signed_contract: false
    }
  });
  const { teamOptions, users, setUserModalData } = useContext(Context);
  const [inviteeData, setInviteeData] = useState<FormState>();
  const [commissionsInfo, setCommissionsInfo] = useState<CommissionsData>();
  const [step, setStep] = useState<number>(0);
  const invitedEmail = useRef<string>('');

  const onProceed = async (values: FormState) => {
    if (!values.has_signed_contract) {
      const commissionsInfo = await loadCommissionsInfo();
      setInviteeData(values);
      setCommissionsInfo(commissionsInfo);
      return setStep(1);
    }

    try {
      await inviteUser(values);
      notify(`Invitation was sent.`);
      handleClose();
    } catch (err) {
      const errorMessage = tryGetFirstError(err);
      notify(errorMessage);
    }
  };

  const onSubmit = async values => {
    const mergedFormValues = { ...inviteeData, ...values };

    try {
      const { signUrl } = await inviteUser(mergedFormValues);

      handleClose();
      if (signUrl) window.open(signUrl);
    } catch (err) {
      const errorMessage = tryGetFirstError(err);
      notify(errorMessage);
    }
  };

  const inviteUser = async (values: FormState) => {
    if (values.has_signed_contract) invitedEmail.current = values.email;

    return await inviteUserRequest(values);
  };

  const handleClose = useCallback(() => {
    onClose?.();
    reset();
    setStep(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invitedEmail.current) {
      const invitedUser: TeamMember | undefined = users.find(
        user => user.email === invitedEmail.current
      );
      setOpenTab?.(AgentTabKeys.Contracts);
      setUserModalData(invitedUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, invitedEmail]);

  return (
    <StyledTeamModal
      className="invite-modal"
      modalTitle="Invite User"
      onClose={handleClose}
      {...props}>
      {step > 0 ? (
        <DefaultCommissionsForm
          data={commissionsInfo}
          onSubmit={onSubmit}
          buttonText="Proceed"
        />
      ) : (
          <form className="invite-user-form" onSubmit={handleSubmit(onProceed)}>
            <Input
              {...register('email', {
                required: 'Required',
                pattern: {
                  value: REGEXP.EMAIL,
                  message: 'Invalid email address'
                }
              })}
              error={errors.email?.message}
              label="Invite Email*"
              placeholder="Enter email"
            />
            <Input
              {...register('name', {
                required: 'Required'
              })}
              label="Invitee Full Name*"
              placeholder="Enter name"
            />
            <MaskInputController
              control={control}
              name="phone"
              rules={{
                validate: value => !value?.includes('_') || 'Invalid phone format'
              }}
              mask="(999) 999-9999"
              error={errors.phone?.message}
              label="Invite Phone"
              placeholder="Enter phone number"
            />
            <Controller
              name="team_id"
              control={control}
              render={({ field }) => (
                <Dropdown options={teamOptions} label="Team" placeholder="Select" {...field} />
              )}
            />
            <Input {...register('position')} label="Position" placeholder="Enter position" />
            <Checkbox {...register('has_signed_contract')} label="User has already signed the contract" />
            <Button className="send-invite-btn" disabled={formState.isSubmitting}>
              Send Invite
        </Button>
          </form>
        )}
    </StyledTeamModal>
  );
};

export default React.memo(InviteUser);

const StyledTeamModal = styled(Modal)`
  .modal-content {
    width: 320px;
  }
  .input,
  .button {
    width: 100%;
  }
  .invite-user-form {
    display: grid;
    gap: 16px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .modal-content {
      width: 100%;
    }
  }
`;
